import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "../../node_modules/tachyons/css/tachyons.min.css"

const Layout = props => (
  <main className={classNames("", props.className)}>
    {props.children}
  </main>
)

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout