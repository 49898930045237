import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Img from "gatsby-image"
import Div100vh from "react-div-100vh"

const FullScreenBackground = props => (
  <Div100vh className={classNames("w-100 relative", props.className)}>
    <Img className="w-100 h-100" fluid={props.fluid} alt={props.alt} />

    <div className="w-100 h-100 absolute top-0 left-0">
      {props.children}
    </div>
  </Div100vh>
)

FullScreenBackground.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
  fluid: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default FullScreenBackground