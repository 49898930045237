import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/Layout"
import FullScreenBackground from "../components/FullScreenBackground"
import Logo from "../assets/bythesea-logo.svg"

const IndexPage = () => {
  const { site, file } = useStaticQuery(query) 
  const { fluid, fixed } = file.childImageSharp
  const alt = "By the sea candles behind palm leaves next to a pool"
  const { title, description, url } = site.siteMetadata

  return (
    <Layout>
      <Helmet>
        <title>{title} | {description}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={fixed.src} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={title} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={alt} />
      </Helmet>

      <FullScreenBackground 
        fluid={fluid} 
        alt={alt}
      >
        <div className="w-100 h-100 overflow-hidden white bg-black-30 flex flex-column items-center justify-between pv4">
          <Logo className="w4 h4" style={{ flexShrink: 0 }} alt="By the sea logo" />
          
          <ul className="list mt5 mb0 mh0 pa0 sans-serif ttu tracked f7 fw6 flex items-center">
            <li className="mr3 mr4-l">
              <a className="link white dim" href="https://www.instagram.com/bytheseacostarica/">Instagram</a>
            </li>
            
            <li className="mr3 mr4-l">
              <a className="link white dim" href="https://www.facebook.com/bytheseacostarica/">Facebook</a>
            </li>
            
            <li className="mr3 mr4-l">
              <a className="link white dim" href="mailto:By the sea<puravida@bythesea.store>">Contact</a>
            </li>
          </ul>
        </div>
      </FullScreenBackground>
    </Layout>
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
    file(relativePath: {eq: "bythesea-candles-poolside.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        } 
        fixed(width: 1200, height: 630) {
          src
        }
      }
    }
  }
`

export default IndexPage
